import React from 'react'

class UrlGenerator extends React.Component {
  state = {
    campaignName: '',
    recordCode: '',
    searchString: '',
  }

  copyToClipboard(id) {
    console.log(id)
    document.getElementById(id).select()
    document.execCommand('copy')
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { campaignName, recordCode, searchString, filter, intURL } = this.state
    const baseUrl = 'https://www.spiralclassics.co.uk/stock-list'
    const utm = `utm_source=newsletter&utm_medium=email&utm_campaign=${campaignName}`
    const url1 = `${baseUrl}?recordCode=${encodeURIComponent(recordCode)}&${utm}`
    const url2 = `${baseUrl}?search=${encodeURIComponent(searchString)}&${utm}`
    const url3 = `${baseUrl}?filter=${encodeURIComponent(filter)}&${utm}`
    const both = `${baseUrl}?search=${encodeURIComponent(searchString)}&recordCode=${encodeURIComponent(recordCode)}&${utm}`
    const internalURL = `${intURL}?${utm}`

    return (
      <div style={{margin: 30}}>
        <form style={{margin: 0}}>
          <label for="campaignName">Campaign name: </label>
          <input
            type="text"
            name="campaignName"
            id="campaignName"
            value={this.state.campaignName}
            onChange={this.handleChange}
          />
          <br />
          <br />
          <label for="recordCode">Record code: </label>
          <input
            type="text"
            name="recordCode"
            id="recordCode"
            value={this.state.recordCode}
            onChange={this.handleChange}
          />
        </form>
        <form style={{margin: 0}}>
          <label for="searchString">Search string (long search box): </label>
          <input
            type="text"
            name="searchString"
            id="searchString"
            value={this.state.searchString}
            onChange={this.handleChange}
          />
        </form>
        <form style={{margin: 0}}>
          <label for="filter">Filter by category: </label>
          <input
            type="text"
            name="filter"
            id="filter"
            value={this.state.filter}
            onChange={this.handleChange}
          />
        </form>
        <form style={{margin: 0}}>
          <label for="intURL">Spiral classics URL: </label>
          <input
            type="text"
            name="intURL"
            id="intURL"
            value={this.state.intURL}
            onChange={this.handleChange}
            style={{width: 800}}
          />
        </form>
        <br />
        <div style={{marginBottom: 5}}>
          <input style={{position: "absolute", left: -9999}} type="text" id="url1" value={url1}/>
          <b>Record code link: <a href={url1}>{url1}</a></b>
          <button style={{marginLeft: 10}} onClick={() => this.copyToClipboard("url1")}>Copy</button>
        </div>
        <div style={{marginBottom: 5}}>
          <input style={{position: "absolute", left: -9999}} type="text" id="url2" value={url2}/>
          <b>Long search box link: <a href={url2}>{url2}</a></b>
          <button style={{marginLeft: 10}} onClick={() => this.copyToClipboard("url2")}>Copy</button>
        </div>
        <div style={{marginBottom: 5}}>
          <input style={{position: "absolute", left: -9999}} type="text" id="url3" value={url3}/>
          <b>Filter by category: <a href={url3}>{url3}</a></b>
          <button style={{marginLeft: 10}} onClick={() => this.copyToClipboard("url3")}>Copy</button>
        </div>
        <div style={{marginBottom: 5}}>
          <input style={{position: "absolute", left: -9999}} type="text" id="both" value={url2}/>
          <b>Record code and long search box link: <a href={both}>{both}</a></b>
          <button style={{marginLeft: 10}} onClick={() => this.copyToClipboard("both")}>Copy</button>
        </div>
        <div style={{marginBottom: 5}}>
          <input style={{position: "absolute", left: -9999}} type="text" id="internalURL" value={url2}/>
          <b>Spiral Classics URL: <a href={internalURL}>{internalURL}</a></b>
          <button style={{marginLeft: 10}} onClick={() => this.copyToClipboard("internalURL")}>Copy</button>
        </div>
      </div>
    )
  }
}

export default UrlGenerator
